<template>
  <Layout>
    <PageHeader title="Edit Pharmacy Invoice" />
    <div class="row p-4 flex justify-center">
      <div class="col-md-10 p-4 bg-light">
        <div class="form-group">
          <label for="Custommer-number">Customer Internal Number</label>
          <input
            type="text"
            placeholder="enter custommer internal Number"
            class="form-control"
            v-model="results.clientInternalNumber"
            @change="updateCIN"
          />
        </div>

        <!-- Dynamic inputs -->
        <div class="form-group">
          <label for="Customer Type"> Select Customer Type</label>
          <select
            style="background-color: #d3d3d3;"
            disabled
            v-model="clienttypeid"
            class="form-control"
            name="customer type"
          >
            <option selected value="1">Oonpay customer</option>
          </select>
        </div>

        <div v-if="clienttypeid == 1" class="form-group">
          <div class="form-group">
            <label for="">Phone Number</label>
            <div class="form-row">
              
              <div class="col-md-12">
                <input
                  style="background-color: #d3d3d3;"
                  disabled
                  :value="invoice.clientPhoneNumber"
                  @change="updateClientNumber"
                  type="text"
                  placeholder="Enter phone number"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Dynamic input -->
        <div class="form-group py-4 space-x-4">
          <label for="Service">Medication(s) Offered</label>
          <button
            data-toggle="modal"
            data-target="#exampleModalCenter"
            type="button"
            class="btn btn-success btn-sm rounded-xl"
          >
            <i class="fa fa-plus-square mr-1" aria-hidden="true"></i>Add
            medication
          </button>
          <div
            v-for="(medi, key) in medications"
            :key="key"
            class="result bg-oonpayprimary text-white px-4 rounded-2xl py-1 mt-3"
          >
            <div class="d-flex justify-content-between">
              <span>{{ medi.selectedMedicine }}</span>
              <span class="space-x-4"
                ><span>{{ medi.quantity }} Units</span>
                <span> GHC{{ medi.unitPrice }}</span></span
              >
            </div>
            <div class="d-flex justify-content-between">
              <span
                >Total Price: GHC{{
                  Math.round(medi.unitPrice * medi.quantity)
                }}</span
              >
              <span @click="DeleteMedUpdate(key)" class="text-oonpaysecondary"
                ><i class="fa fa-trash" aria-hidden="true"></i
              ></span>
            </div>
          </div>

          <div v-if="results.servicesOffered">
            <div
              v-for="(medi, key) in results.servicesOffered"
              :key="key"
              class="result bg-oonpayprimary text-white px-4 rounded-2xl py-1 mt-3"
            >
              <div class="d-flex justify-content-between">
                <span>{{ medi.serviceFullName }}</span>
                <span class="space-x-4"
                  ><span>{{ medi.serviceQuantity }} Units</span>
                  <span> GHC{{ medi.serviceUnitPrice }}</span></span
                >
              </div>
              <div class="d-flex justify-content-between">
                <span
                  >Total Price: GHC{{
                    medi.serviceUnitPrice * medi.serviceQuantity
                  }}</span
                >
                <span @click="DeleteMed(key,medi.id)" class="text-oonpaysecondary"
                  ><i class="fa fa-trash" aria-hidden="true"></i
                ></span>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal -->
        <div
          class="modal fade"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Add Medication
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <!-- Add Medication -->
                <div class="form-group">
                  <label for="Medication">
                    Medication
                  </label>
                  <input
                    class="form-control"
                    name=""
                    type="text"
                    v-model="searchQuery"
                  />
                  <div v-if="searchQuery">
                    <!-- <div v-for="r of resultQuery" :key="r.id">{{r.fullName}}</div> -->

                    <ul v-if="!isHidden" class="list-group">
                      <li
                        class="list-group-item"
                        v-for="(suggestion, index) in resultQuery"
                        :key="index"
                        @click="suggestionClick(suggestion.id)"
                      >
                        <a class="text-oonpaysecondary" href="#"
                          >{{ suggestion.fullName }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="form-group">
                  <label for="Qunatity">Quantity</label>
                  <input
                    type="number"
                    v-model="quantity"
                    placeholder="0"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label for="unit price">Unit Price</label>
                  <input
                    type="number"
                    v-model="unitPrice"
                    placeholder="0"
                    class="form-control"
                  />
                </div>

                <button
                  @click="addMedication"
                  type="button"
                  class="btn btn-info btn-block"
                >
                  <i class="fa fa-medkit" aria-hidden="true"></i> Add Medication
                </button>

                <div
                  v-for="(medi, key) in medications"
                  :key="key"
                  class="result bg-oonpayprimary text-white px-4 rounded-2xl py-1 mt-3"
                >
                  <div class="d-flex justify-content-between">
                    <span>{{ medi.selectedMedicine }}</span>
                    <span class="space-x-4"
                      ><span>{{ medi.quantity }} Units</span>
                      <span> GHC{{ medi.unitPrice }}</span></span
                    >
                  </div>
                  <div class="d-flex justify-content-between">
                    <span
                      >Total Price: GHC{{
                        Math.round(medi.unitPrice * medi.quantity)
                      }}</span
                    >
                    <span
                      @click="DeleteMedUpdate(key)"
                      class="text-oonpaysecondary"
                      ><i class="fa fa-trash" aria-hidden="true"></i
                    ></span>
                  </div>
                </div>

                <div v-if="results.servicesOffered">
                  <div
                    v-for="(medi, key) in results.servicesOffered"
                    :key="key"
                    class="result bg-oonpayprimary text-white px-4 rounded-2xl py-1 mt-3"
                  >
                    <div class="d-flex justify-content-between">
                      <span>{{ medi.serviceFullName }}</span>
                      <span class="space-x-4"
                        ><span>{{ medi.serviceQuantity }} Units</span>
                        <span> GHC{{ medi.serviceUnitPrice }}</span></span
                      >
                    </div>
                    <div class="d-flex justify-content-between">
                      <span
                        >Total Price: GHC{{
                          medi.serviceUnitPrice * medi.serviceQuantity
                        }}</span
                      >
                      <span @click="DeleteMed(key, medi.id)" class="text-oonpaysecondary"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="results.servicesOffered" class="modal-footer">
                <p class="font-bold" style=" margin-right:120px;">
                  <span class="font-bold text-2xl"
                    >Total Cost:
                    {{ Math.round(totalSumMed + calCulateTotal) }}</span
                  >
                </p>
                <button type="button" class="btn btn-info" data-dismiss="modal">
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="Total Cost">Total Cost </label>
              <input
                style="background-color: #d3d3d3;"
                disabled
                type="number"
                class="form-control text-muted"
                placeholder="total cost"
                :value="calCulateTotal + totalSumMed"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="payment due">Select Payment Due Date</label>
              <input
                type="date"
                name="date"
                class="form-control"
                placeholder="enter payment due date"
                v-model="duedate"
                @change="UpdateDueDate"
              />
            </div>
          </div>
        </div>

        <div class="form-group my-4">
          <div class="custom-file">
            <input
              type="file"
              @change="uploadFile"
              class="custom-file-input"
              multiple
              ref="files"
            />
            <label class="custom-file-label" for="upload docs"
              >upload documents here</label
            >
            <div
              v-for="(file, key) in Documents"
              :key="key"
              class="result bg-oonpayprimary text-white px-4 mx-40 rounded-2xl py-1 mt-3"
            >
              <div class="d-flex justify-content-between">
                <span> {{ file.name }}</span>
                <span
                  @click="deleteUpdatedDoc(key)"
                  class="text-oonpaysecondary"
                  ><i class="fa fa-trash" aria-hidden="true"></i
                ></span>
              </div>
            </div>
            <div v-if="results.documents">
              <div
                v-for="(medi, key) in results.documents"
                :key="key"
                class="result bg-oonpayprimary text-white px-4 mx-40 rounded-2xl py-1 mt-3"
              >
                <div class="d-flex justify-content-between">
                  <span>{{
                    medi.fullName.length >= 30
                      ? medi.fullName.substring(0, 30)
                      : medi.fullName
                  }}</span>
                  <span @click="deleteDoc(key)" class="text-oonpaysecondary"
                    ><i class="fa fa-trash" aria-hidden="true"></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="Customer Phone Number"></label>
          <div class="form-row">
            <div class="col-sm-3">
              <select
                v-model="selected_callCode"
                class="form-control"
                name="countries"
              >
                <option
                  v-for="country in countries_code"
                  :key="country.index"
                  :value="country"
                >
                  {{ country.callCode }}
                </option>
              </select>
            </div>
            <div class="col-sm-6">
              <input
                type="number"
                v-model="benefactor"
                class="form-control"
                placeholder="enter benefactory phone"
              />
            </div>
            <div class="col-md-3">
              <button @click.prevent="getBenefactor" class="btn btn-primary">
                Add
              </button>
            </div>
          </div>
          <div
            v-if="results && results.benefactors"
            class="row flex mt-3 justify-center"
          >
            <div class="col-md-6">
              <ul
                v-for="(num, key) in results.benefactors"
                :key="key"
                class="list-group"
              >
                <li
                  class="list-group-item bg-oonpayprimary  d-flex justify-between  "
                >
                  <span>
                    {{ num.clientFullName }} ({{ num.clientPhoneNumber }})</span
                  >
                  <span @click="deleteNumber(key, num.id)"
                    ><span
                      class="iconify"
                      data-icon="fluent:delete-dismiss-28-filled"
                      data-inline="false"
                    ></span
                  ></span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <button
          @click.prevent="handleSubmitInvoice"
          class="btn my-4 btn-info btn-sm btn-block"
        >
          Update Invoice
        </button>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import moment from "moment";
export default {
  components: { Layout, PageHeader },
  data() {
    return {
      clientinternalnumber: "",
      clientfirstname: "",
      clientlastname: "",
      clientemail: "",
      clienttypeid: 1,
      clientcountryId: "",
      clientephone: "",

      totalcost: "",
      duedate: "",
      benefactors: [],
      Documents: [],
      ServicesOffered: [],
      selected_callCode: null,
      confirm_otp: null,
      show_confirm: 1,

      options: [],
      isHidden: false,

      medications: [],
      value: [],
      selectedMedicine: null,
      minMatchingChars: 1,
      quantity: null,
      unitPrice: null,
      newvalue: "",
      clientcountrycode: "",
      clientphonenum: "",

      searchQuery: null,
      isActive: true,

      mediId: null,
      phonenumbers: [],
      countries_code: [],
      phone: "",
      files: null,
      benefactor: "",
      benefactorsGuid: [],

      selected_code: null,
      customerphonenumber: null,

      customer_type: "",
      merchantguid: window.localStorage.getItem("merchantGUID"),
      employeeGUID: window.localStorage.getItem("employeeGUID"),

      invoices: [],
      invoice: {},
      invoice_guid: "",
      results: [],
      totalSumMed: 0
    };
  },

  methods: {
    updateClientNumber(e) {
      this.$store.dispatch("pharmacy/UpdateClientNum", e.target.value);
    },
    updateCIN(e) {
      this.$store.dispatch("pharmacy/UpdateCIN", e.target.value);
    },
    UpdateDueDate(e) {
      this.$store.dispatch("pharmacy/UpdateDueDate", e.target.value);
    },
    getAllInvoices() {
      this.$store.dispatch("invoice/getAllInvoice").then((response) => {
        this.invoices = response.data;
        this.invoices.forEach((invoice) => {
          if (invoice.id == this.$route.params.id) {
            this.invoice = invoice;
            console.log(this.invoice)
            this.duedate = moment(this.invoice.dueDate).format("YYYY-MM-DD");
            
            this.totalSumMed = invoice.cost
            this.invoice_guid = invoice.guid;

            this.GetInvoiceRequiredData();
          }
        });
      });
    },

    suggestionClick(index) {
      this.options.forEach((data) => {
        if (data.id == index) {
          this.searchQuery = data.fullName;
          this.mediId = data.id;
          this.isHidden = true;
        }
      });
    },

    //Remove List
    DeleteMed(key,id) {
      this.results.servicesOffered.forEach(service=>{
          if(service.id == id){
            let total = service.serviceQuantity * service.serviceUnitPrice
            this.totalSumMed = this.totalSumMed - total
             this.results.servicesOffered.splice(key, 1);
          }
      })
    },
    // Get Invoice Required data
    GetInvoiceRequiredData() {
      const data = {
        id: this.merchantguid,
        guid: this.invoice_guid,
      };
      this.$store
        .dispatch("pharmacy/GetInvoiceRequiredData", data)
        .then(({ data }) => {
          this.options = data.item2;
          this.results = data.item3;

          this.countries_code = data.item1;
        });
    },

    //add medication
    addMedication() {
      const data = {
        selectedMedicine: this.searchQuery,
        quantity: this.quantity,
        unitPrice: this.unitPrice,
        id: this.mediId || 0,
        total: this.quantity * this.unitPrice,
      };
      this.medications.push(data);
      (this.searchQuery = ""), (this.unitPrice = ""), (this.quantity = "");
      this.mediId = "";
      this.isHidden = false;
    },

    DeleteMedUpdate(key) {
      this.medications.splice(key, 1);
    },

    // Benefactor
    getBenefactor() {
      let newPhone = this.benefactor;

      if (newPhone.charAt(0) == 0 || newPhone.charAt(0) == 1) {
        var benefactornumber = newPhone.substring(1);
      }

      let main_number = `${this.selected_callCode.callCode}${benefactornumber ||
        newPhone}`;
      const data = {
        phonenumber: main_number,
      };

      this.$store
        .dispatch("invoice/GetBenefactor", data)
        .then((response) => {
          if (response.status == 200) {
            this.results.benefactors.push({
              id: response.data.id,
              clientPhoneNumber: main_number,
              clientFullName:
                response.data.firstName + " " + response.data.lastName,
              clientGUID: response.data.guid,
            });

            this.benefactor = "";
          } else {
            this.$toasted.error("Sorry Your number is not on Oonpay", {
              duration: 5000,
            });
          }
        })
        .catch(() => {
          this.$toasted.error("Your number is not on Oonpay", {
            duration: 5000,
          });
        });
    },

    // delete number
    deleteNumber(key) {
      this.results.benefactors.splice(key, 1);
    },

    deleteDoc(key) {
      this.results.documents.splice(key, 1);
    },

    uploadFile(e) {
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.Documents.push(this.$refs.files.files[i]);
      }
    },

    deleteUpdatedDoc(key) {
      this.Documents.splice(key, 1);
    },

    handleSubmitInvoice() {
      //get services offered guid array
      const services = this.medications.map((item) => {
        const container = {
          ServiceFullName: item.selectedMedicine,
          ServiceQuantity: item.quantity,
          ServiceUnitPrice: item.unitPrice,
          ServiceId: item.id,
        };

        return container;
      });

      //get benefactor numbers
      let benefactor = this.results.benefactors.map((item) => {
        return item;
      });

      // JSON.stringify(services)
      const formData = new FormData();
      formData.append("clienttypeid", this.clienttypeid);
      formData.append(
        "clientinternalnumber",
        this.$store.state.pharmacy.customer_internal_num ||
          this.results.clientInternalNumber
      );
      formData.append(
        "ClientPhoneNumber",
        this.$store.state.pharmacy.client_num || this.invoice.clientPhoneNumber
      ),
        formData.append("timeStamp", JSON.stringify(this.results.timeStamp));
      formData.append("GUID", this.invoice.guid);
      formData.append("EmployeeGUID", this.employeeGUID);
      formData.append(
        "TotalCost",
        this.totalSumMed + this.calCulateTotal
      );
      formData.append("DueDate", this.duedate);
      formData.append("ServiceStartDate", "2020-09-10");
      formData.append("ServiceEndDate", "2020-09-10");
      formData.append("benefactors", JSON.stringify(benefactor));
      formData.append("Documents", JSON.stringify(this.results.documents));
      var self = this;
      for (let i = 0; i < this.Documents.length; i++) {
        let file = self.Documents[i];
        formData.append("New File", file);
      }
      formData.append(
        "ServicesOffered",
        services
          ? JSON.stringify(this.results.servicesOffered.concat(services))
          : JSON.stringify(this.results.servicesOffered)
      );

      this.$store
        .dispatch("pharmacy/UpdateInvoice", formData)
        .then((response) => {
          if (response.status == 200) {
            this.$router.push("/pharmacy-invoices");
            this.$toasted.success("Invoice updated successfully", {
              duration: 5000,
            });
          }
        })
        .catch(() => {
          this.$toasted.error(
            "Invalid inputs or check and fill all required inputs",
            { duration: 5000 }
          );
        });
    },
  },

  computed: {
    resultQuery() {
      if (this.searchQuery) {
        return this.options.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => item.fullName.toLowerCase().includes(v));
        });
      } else {
        return this.resources;
      }
    },

    calCulateTotal() {
      var sum = 0;

      this.medications.filter((med) => {
        sum += med.total;
      });

      this.$store.dispatch("pharmacy/SET_SUM", sum);
      return sum;
    },

    getCustomerPhone() {
      let phonenumber =
        this.$store.state.pharmacy.mainphonenumber || "Customer phone number";
      return phonenumber;
    },
  },
  mounted() {
    this.getCustomerPhone;
    this.getAllInvoices();
  },
};
</script>

<style scoped>
.iconify {
  color: red !important;
}

.btn-info {
  background: #f15825 !important;
  border: none;
}

.btn-success {
  background: #0c4369 !important;
  border: none;
}
</style>
